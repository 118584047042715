'use client';

// All Next components are server by default in 13+, have to include 'use client' at the top
// of a component to determine it as a client-side component
// https://stackoverflow.com/questions/74471642/nextjs-13-button-onclick-event-handlers-cannot-be-passed-to-client-componen

import { MenuItem } from '@/lib/api';
import Link from 'next/link';

export const NavLink = (props: { node: MenuItem }) => {
    const setRandomKey = (id: string, length: number) => {
        const chars =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';

        for (let i = 0; i < length; i++) {
            randomString += chars.charAt(
                Math.floor(Math.random() * chars.length)
            );
        }

        return id + randomString;
    };

    const handleMouseEnter = (id: string) => {
        document.getElementById(id)?.classList.add('active');
    };

    const handleMouseLeave = (id: string) => {
        document.getElementById(id)?.classList.remove('active');
    };

    const renderMenuItem = (item: MenuItem, currentIteration = 0) => {
        if (item.children.length > 0) {
            return (
                <div key={setRandomKey(item.id, 10)}>
                    <div
                        key={setRandomKey(item.id, 10)}
                        style={{
                            position: `relative`,
                        }}
                        onMouseEnter={() => handleMouseEnter(item.id)}
                        onMouseLeave={() => handleMouseLeave(item.id)}
                    >
                        <p
                            key={setRandomKey(item.id, 10)}
                            style={
                                currentIteration > 0
                                    ? {
                                          color: '#000',
                                          padding: '1rem',
                                      }
                                    : {}
                            }
                        >
                            {item.label}
                        </p>

                        <div
                            id={item.id}
                            key={setRandomKey(item.id, 10)}
                            className='nav__nestedMenu'
                            style={
                                currentIteration > 0
                                    ? {
                                          left: `100%`,
                                          top: `50%`,
                                      }
                                    : {
                                          top: `72%`,
                                      }
                            }
                        >
                            {item.children.map((item: MenuItem) => {
                                return renderMenuItem(
                                    item,
                                    currentIteration + 1
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <p className='me-3' key={setRandomKey(item.id, 10)}>
                <Link
                    id=''
                    href={item.uri}
                    className='hover:underline'
                    dangerouslySetInnerHTML={{ __html: item.label }}
                    onMouseEnter={() => handleMouseEnter(item.id)}
                    onMouseLeave={() => handleMouseLeave(item.id)}
                />
            </p>
        );
    };

    return renderMenuItem(props.node);
};
